import { faRobot } from "@fortawesome/free-solid-svg-icons";
import useRoles from "Hooks/UseRoles";
import { useMemo } from "react";
import { ROLES } from "Types/Enums/Roles";
import SidebarNavItem from "../SidebarNavItem/SidebarNavItem";

const GenerateAiLink = () => {
    const requiredRoles = useMemo(() => new Set([ROLES.UNDERWRITE_VALUER as string]), []);

    const { roles } = useRoles();

    const show = useMemo(() => {
        return (roles ?? []).some(r => requiredRoles.has(r));
    }, [requiredRoles, roles]);
    return (
        <>
            {show && (
                <SidebarNavItem
                    navLink={{
                        label: "Generate AI",
                        icon: faRobot,
                        to: "/ai",
                    }}
                />
            )}
        </>
    );
};

export default GenerateAiLink;
